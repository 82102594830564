import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { FirebaseUISignInSuccessWithAuthResult, FirebaseUISignInFailure, firebase } from 'firebaseui-angular';
import { Router } from '@angular/router';

import {LoadingService} from '../service/loading.service'
import {EventsService} from '../service/events.service';
import {ApiService} from '../service/api.service';
import {MessagingService} from '../service/messaging.service';

import { Platform, } from '@ionic/angular';

declare function require(string): any;
var firebaseui = require('firebaseui-ja'); 
const publicIp = require('public-ip');

@Component({
  selector: 'app-login-firebase-ui',
  templateUrl: './login-firebase-ui.component.html',
  styleUrls: ['./login-firebase-ui.component.scss'],
})
export class LoginFirebaseUIComponent implements OnInit {

  user: Observable<firebase.default.User>;
  
  platformIsIos
  pushToken
  pushMessage
  ip
  afId
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    public events: EventsService,
    public loadingService: LoadingService,
    public api: ApiService,
    public messagingService: MessagingService,
    private platform: Platform,
  ) {
  

    this.afId = this.api.afId

  }

  async ngOnInit() {
    this.user = this.angularFireAuth.authState;

    this.user = this.angularFireAuth.authState;

    this.ip = await publicIp.v4()
    //await publicIp.v6()
    //console.log("ip " + this.ip)
    this.messagingServiceInit()
   
  }

  async messagingServiceInit(){
    let browserName = navigator.userAgent.toLowerCase()
    let platformIsIos = false
    await this.platform.platforms().forEach((item)=>{
      if(item.toLowerCase() == "ios" || item.toLowerCase() == "iphone" ){
        platformIsIos = true
      }
    })
    
    if( !platformIsIos || !browserName.includes('safari')){
      this.messagingService.requestPermission()
      this.messagingService.receiveMessage()
      this.pushMessage = this.messagingService.currentMessage
    }
  }


  // ログアウト
  async logout() {
    this.api.user = null
    this.angularFireAuth.signOut();
  }

  // 成功時のコールバック
  async successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    //console.log(" XXXXXXXXXXXX " + signInSuccessData);

/*
    await this.storage.set('user_data', {
      uid: signInSuccessData.authResult.user.uid, 
    });
*/
      await firebase.database().ref('accounts/' + signInSuccessData.authResult.user.uid )
      .once("value").then((snap) => {
        if(snap.val()){

          //console.log("user " + JSON.stringify(signInSuccessData.authResult.user))

          // apiに頒布
          this.api.user = snap.val()
          this.api.userId = signInSuccessData.authResult.user.uid
          //console.log("XXXXXXXXXXXXX " + this.api.userId)
         
          if(snap.val().createdate){
            this.renewUserData(signInSuccessData.authResult.user)
          }else{
            this.createUserData(signInSuccessData.authResult.user)
          }
          //this.createUserData(signInSuccessData.authResult.user)
        }else{
          //console.log("new User ")
          this.createUserData(signInSuccessData.authResult.user)
        }
      }).catch((error)=>{

    }).catch((error)=>{})
  }

  //　アカウント更新
  async renewUserData(str){
    let user = str
    // iPhone以外プッシュトークンの取得処理
    let pushToken = await this.messagingService.pushToken
    if(pushToken == null || pushToken == undefined){
      pushToken == null
    }
    this.pushToken = pushToken // プッシュトークンset
    
    firebase.database().ref('accounts/' + user.uid )
    .update({
      ip: this.ip,
      lastlogin: new Date().getTime(),
      displayName: user.displayName,
      photoURL: user.photoURL,
      token: pushToken,
      tokenget: new Date().getTime(),
    }).catch((error)=>{})
  }


  // 新規ユーザーのデータ生成とtoken取得
  async createUserData(str){

    //console.log("createUserData ")
    //await this.chkPlatform()
    let user = str
    let providerId
    user.providerData.forEach((item)=>{
      if(item.providerId != undefined){
        providerId = item.providerId
        return
      }
    })

    let hash = await this.createHash(str.uid)

    //console.log("updateToken ############### ")
    // iPhone以外プッシュトークンの取得処理
    let pushToken = await this.messagingService.pushToken
    if(pushToken == null || pushToken == undefined){
      pushToken == null
    }
    this.pushToken = pushToken // プッシュトークンset

    let createdate = new Date().getTime()

    let name = "未設定"
    if(user.displayName){
      name = user.displayName
    }

    if(this.ip == undefined){
      this.ip = null
    }

    let json = {
      afId: this.afId,
      hash: hash,
      ip: this.ip,
      userId: user.uid,
      displayName: user.displayName,
      photoURL: user.photoURL,
      email: user.email,
      providerId: providerId,
      name: name,
      token: pushToken,
      tokenget: new Date().getTime(),
      createdate: createdate,
      lastlogin: new Date().getTime(),
      date: new Date().getTime() //ユーザーデータ作成日
    }

    await firebase.database().ref('accounts/' + user.uid )
    .update(json).then(()=>{
      // apiに頒布
      this.api.user = json
    }).then(()=>{

      this.ngZone.run(() => {
        // ログイン成功ならユーザーページへ
        this.router.navigate(['/']);
      });
    }).catch((error)=>{})

  }




  createHash(str){
    let hash
    // hash　idからハッシュの作成
    return new Promise((resolve,reject)=>{ 
      let hashCode = function(s) {
        var h = 0, l = s.length, i = 0;
        if ( l > 0 )
          while (i < l)
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
        return h;
      };
      
      // マイナスのあたいはプラスにして、重複が心配なので先頭に M 入れておく
      hash = hashCode(str)
      if(Math.sign(hash) < 0){
        hash = hash * -1
        hash = "M" + String(hash)
      }else{
        hash = "P" + String(hash)
      }
      
      //console.log(".hashCode() " + hashCode("-MU7JJePgnIg-WD4Y4VK"))
      resolve(hash)
      //console.log("444" + hash)
    }).then(()=>{
      return hash
    })
  }




  // 失敗時のコールバック
  async errorCallback(errorData: FirebaseUISignInFailure) {
    //console.log(errorData);
  }


}
