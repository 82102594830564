import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { Platform, } from '@ionic/angular';
import { SwPush } from '@angular/service-worker';
import {EventsService} from './events.service';


@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  pushToken
 
currentMessage = new BehaviorSubject(null);
 
constructor(private angularFireMessaging: AngularFireMessaging,
  private platform: Platform,
  private swPush: SwPush,
  public events: EventsService,
  ) {

  let browserName = navigator.userAgent.toLowerCase()
  //alert(browserName.includes('safari'))
  // ブラウザオープン時
  if(!this.platform.is('ios') || !browserName.includes('safari')){
    
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        //_messaging.onMessage = _messaging.onMessage.bind(_messaging);
        //_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        // //console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
    })
  }
    

  }

  async ngOnInit() {
  }
 
  
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
    (token) => {
    //console.log(token);
      this.pushToken = token
    });
  }
 
  // 未使用
  
  receiveMessage() {
    // //console.log("##########################receiveMessage ----")
    this.angularFireMessaging.messages.subscribe(
      (msg) => {
      // //console.log("show message!", msg);
      this.currentMessage.next(msg);
    })
  }
  
  

}


