import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/:afId',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'job',
    loadChildren: () => import('./job/job.module').then( m => m.JobPageModule)
  },
  {
    path: 'job/:release',
    loadChildren: () => import('./job/job.module').then( m => m.JobPageModule)
  },
  {
    path: 'human',
    loadChildren: () => import('./human/human.module').then( m => m.HumanPageModule)
  },
  {
    path: 'human/:release',
    loadChildren: () => import('./human/human.module').then( m => m.HumanPageModule)
  },
  {
    path: 'human-add',
    loadChildren: () => import('./human-add/human-add.module').then( m => m.HumanAddPageModule)
  },
  {
    path: 'human-add/:humanId',
    loadChildren: () => import('./human-add/human-add.module').then( m => m.HumanAddPageModule)
  },
  {
    path: 'job-add',
    loadChildren: () => import('./job-add/job-add.module').then( m => m.JobAddPageModule)
  },
  {
    path: 'job-add/:jobId',
    loadChildren: () => import('./job-add/job-add.module').then( m => m.JobAddPageModule)
  },
  {
    path: 'modal-show',
    loadChildren: () => import('./modal-show/modal-show.module').then( m => m.ModalShowPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
