import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { ServiceWorkerModule, SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ClipboardModule } from 'ngx-clipboard';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FirebaseUIModule, firebase, /*firebaseui*/ } from 'firebaseui-angular';
import { LoginFirebaseUIComponent } from './login-firebase-ui/login-firebase-ui.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  autoUpgradeAnonymousUsers: false, // 匿名認証ユーザー自動アップグレード
  signInFlow: 'redirect',//,'popup', // redirect or popup
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    /*
    {
      scopes: [
        'public_profile',
        'email',
        'user_likes',
        'user_friends'
      ],
      customParameters: {
        'auth_type': 'reauthenticate'
      },
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    },
    */
    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //firebase.auth.GithubAuthProvider.PROVIDER_ID,
    /* email
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
    */
    //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    //firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  /*
  tosUrl: 'term-of-useのURL',
  privacyPolicyUrl: 'プライバシーポリシーのURL',
  signInSuccessUrl: 'https://google.com',
  credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
  siteName: 'my-app', 
  */
};

const firebaseConfig = {
  apiKey: "AIzaSyAjIuUWLUV3qfdzunbDTr1CWFnCUdJG7OA",
  authDomain: "ses-project-86e17.firebaseapp.com",
  databaseURL: "https://ses-project-86e17-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ses-project-86e17",
  storageBucket: "ses-project-86e17.appspot.com",
  messagingSenderId: "652373943696",
  appId: "1:652373943696:web:4bcf9d781928b8201aff15",
  measurementId: "G-QBSRMBHV5Q"
}

firebase.initializeApp(firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    LoginFirebaseUIComponent, // firebaseUI にて認証
  ],
  entryComponents: [],
  imports: [
    ClipboardModule,
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig), 
    AngularFireAuthModule,                            // angularfireのAuth用モジュール  // firebaseUI にて認証
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),　 // FirebaseUIのモジュール         // firebaseUI にて認証
    ServiceWorkerModule.register('ngsw-worker.js',    // service-workser
    { enabled: environment.production }),
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
