import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import {LoadingService} from '../service/loading.service'
import {EventsService} from '../service/events.service';
import {ApiService} from '../service/api.service';

import firebase from 'firebase/app';    //8
import "firebase/database";
import "firebase/auth";

import moment from 'moment';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-show',
  templateUrl: './modal-show.page.html',
  styleUrls: ['./modal-show.page.scss'],
})
export class ModalShowPage implements OnInit {

  // メイン変更
  @Input() page: string;
  @Input() pagename: string;
  @Input() human_history: any;
  



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public events: EventsService,
    public loadingService: LoadingService,
    public api: ApiService,
    private changeDetectorRef: ChangeDetectorRef,
    public modalController: ModalController,
  ) { 

  }

  ngOnInit() {
  }

  initialize(){
    this.page = null
    this.pagename = null
    this.human_history = null
  }

  ionViewWillLeave(){
    this.initialize()
  }


  async dismiss(str) {

    let item = null

    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    await this.modalController.dismiss({
      item: item,
      page: this.page,
      dismissed: true,
    })
    if(str.home == true){
      this.router.navigateByUrl('home', {replaceUrl: true})
      setTimeout(()=>{
        // 表示されているデータの再描画
        this.changeDetectorRef.detectChanges(); //変更された this　の要素を強制的に変更
      }, 800)
    }

  }


}
