import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { ActivatedRoute, Routes, RouterModule } from '@angular/router';

import { ModalController, AlertController, Platform } from '@ionic/angular';
import { ModalShowPage } from '../modal-show/modal-show.page'

import {LoadingService} from '../service/loading.service'
import {EventsService} from '../service/events.service';
import {MessagingService} from '../service/messaging.service';

import firebase from 'firebase/app';    //8
import "firebase/database";
import "firebase/auth";


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url = "https://ses.ipsv.info"

  user
  userId
  afId
  pushToken
  ip

  inviteUrl 
  

  jobList
  jobList_on = undefined
  humanList
  humanList_on = undefined
  myInviteList 
  getMyInviteList_on = undefined

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public events: EventsService,
    public loadingService: LoadingService,
    public modalController: ModalController,
    public messagingService: MessagingService,
    public platform: Platform,
  ) { 

    // firebase リロード対応
    firebase.auth().onAuthStateChanged(user => {
      if(user){
        this.userId = firebase.auth().currentUser.uid
        this.renewUserData(firebase.auth().currentUser)
        
        // ログイン成功ならhomeへ
        this.router.navigate(['/home']);

      }
    })


    this.afId = null
    this.user = null
  }


  async ngOnInit(){
    await this.platform.ready()
    this.initialize()
  }


  initialize(){
    this.jobList = []
  }

  async getMyinfomation(){
    await firebase.database().ref('accounts/' + this.userId )
    .once("value").then((snap) => {
      if(snap.val()){
        this.user = snap.val()
        if(this.user.afId){
          this.afId = this.user.afId
        }else{
          if(this.afId != null ){
            firebase.database().ref('accounts/' + this.userId )
            .update({
              afId: this.afId
            })
          }
        }
        this.getJobList()
        this.getHumanList()
        this.getMyInviteList()
        this.inviteUrl = this.url + "/home/" + this.user.hash
      }
    })
  }

  // 招待リストの取得
  getMyInviteList(){

    if(!this.getMyInviteList_on){

      let this_events = this.events
      this.getMyInviteList_on = firebase.database().ref('invite/' + this.userId)
      this.getMyInviteList_on
      .on("value", function(snapshot, prevChildKey) {
        
        if(snapshot.val() != null){

          // list形式なので一旦 mapかける keyは削除の可能性があるので中に入れる
          let result = Object.keys(snapshot.val()).map(function(item){
            let result_item = snapshot.val()[item]
            result_item.key = item
            return result_item // snapshot.val()[item]
          })

          //console.log("data.changedPost " + JSON.stringify(changedPost))
          this_events.publish('resultMyInviteList', {
            date: new Date().getTime(),
            changedPost: result
          });
        }
      })

      // データの受け取りと生成
      this.events.subscribe('resultMyInviteList' ,(data) => {
        this.myInviteList = data.changedPost
        //console.log("data.changedPost " + JSON.stringify(data.changedPost))
      })
    }
  }


  //　アカウント更新 と取得
  async renewUserData(str){
    let user = str
    // iPhone以外プッシュトークンの取得処理
    let pushToken = await this.messagingService.pushToken
    if(pushToken == null || pushToken == undefined){
      pushToken == null
    }
    this.pushToken = pushToken // プッシュトークンset
    
    if(pushToken == undefined){
      pushToken = null
    }

    firebase.database().ref('accounts/' + user.uid )
    .update({
      lastlogin: new Date().getTime(),
      displayName: user.displayName,
      photoURL: user.photoURL,
      token: pushToken,
      tokenget: new Date().getTime(),
    }).then(()=>{
      this.getMyinfomation()
    })
  }



  // 単語で分けて、ひらがな削除
  async returnTagArray(str){

    if(str){
      let rm =/[一-龠]+|[ぁ-ん]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+/g
      let _array = await str.match(rm)
      let array = []
      await _array.forEach((item)=>{
        if( item.match(/^[ぁ-んー　]*$/) ){
          //console.log("true ひらがな")
        }else{
          array.push(item)
        }
      })
      return array
    }else{
      return null
    }
  }



  getJobList(){

    if(!this.jobList_on){
      this.jobList = []
      let this_events = this.events

      this.jobList_on = firebase.database().ref('jobInfo/').orderByChild('date').limitToLast(10000)
    
      this.jobList_on.on("child_added", function(snapshot, prevChildKey) {
        
        if(snapshot.val() != null){
          let changedPost = snapshot.val();

          //console.log("snapshot.val() " + JSON.stringify(snapshot.val()))
          this_events.publish('addJobList', {
            date: new Date().getTime(),
            changedPost: changedPost
          });
        }
      })
      // データの受け取りと生成
      this.events.subscribe('addJobList'  ,(data) => {
        this.jobList.push(data.changedPost)
        //console.log("xxxx" + JSON.stringify(this.jobList))
      })


      // 案件情報の変更検知
      this.jobList_on.on('child_changed', (snapshot) => {
        const changedPost = snapshot.val();
        //console.log('The updated post title is ' + JSON.stringify(changedPost));
        this_events.publish('renewJobList', {
          date: new Date().getTime(),
          changedPost: changedPost
        });
      });
      // データの受け取りと生成
      this.events.subscribe('renewJobList'  ,(data) => {
        //console.log(data.changedPost.job_name + " " + data.changedPost.jobId )
        this.jobList.forEach((item)=>{
          if(data.changedPost.jobId == item.jobId){
            item = data.changedPost
          }
        })
      })


      // 案件情報の削除検知
      this.jobList_on.on('child_removed', (snapshot) => {
        const changedPost = snapshot.val();
        //console.log('The updated post title is ' + JSON.stringify(changedPost));
        this_events.publish('removeJobList', {
          date: new Date().getTime(),
          changedPost: changedPost
        });
      });
      // データの受け取りと生成
      this.events.subscribe('removeJobList'  ,(data) => {
        this.jobList = this.jobList.filter(function(item, index){//　削除
          if ( item.jobId != data.changedPost.jobId ) return true;
        });
      })
    }
  }




  getHumanList(){

    if(!this.humanList_on){
      this.humanList = []
      let this_events = this.events

      this.humanList_on = firebase.database().ref('humanInfo/').orderByChild('date').limitToLast(10000)
    
      this.humanList_on.on("child_added", function(snapshot, prevChildKey) {
        
        if(snapshot.val() != null){
          let changedPost = snapshot.val();

          //console.log("snapshot.val() " + JSON.stringify(snapshot.val()))
          this_events.publish('addHumanList', {
            date: new Date().getTime(),
            changedPost: changedPost
          });
        }
      })
      // データの受け取りと生成
      this.events.subscribe('addHumanList'  ,(data) => {
        this.humanList.push(data.changedPost)
        //console.log("xxxx" + JSON.stringify(this.humanList))
      })


      // 案件情報の変更検知
      this.humanList_on.on('child_changed', (snapshot) => {
        const changedPost = snapshot.val();
        //console.log('The updated post title is ' + JSON.stringify(changedPost));
        this_events.publish('renewHumanList', {
          date: new Date().getTime(),
          changedPost: changedPost
        });
      });
      // データの受け取りと生成
      this.events.subscribe('renewHumanList'  ,(data) => {
        //console.log(data.changedPost.human_name + " " + data.changedPost.humanId )
        this.humanList.forEach((item)=>{
          if(data.changedPost.humanId == item.humanId){
            item = data.changedPost
          }
        })
      })


      // 案件情報の削除検知
      this.humanList_on.on('child_removed', (snapshot) => {
        const changedPost = snapshot.val();
        //console.log('The updated post title is ' + JSON.stringify(changedPost));
        this_events.publish('removeHumanList', {
          date: new Date().getTime(),
          changedPost: changedPost
        });
      });
      // データの受け取りと生成
      this.events.subscribe('removeHumanList'  ,(data) => {
        this.humanList = this.humanList.filter(function(item, index){//　削除
          if ( item.humanId != data.changedPost.humanId ) return true;
        });
      })
    }
  }





  // 経歴情報の表示
  async openHumanHistoryModal(str){

    //console.log("gotoModal " )
    let name = str.human_nickname
    if(str.userId == this.userId){
      name = str.human_name
    }

    const modal = await this.modalController.create({
      component: ModalShowPage,
      cssClass: 'modal-fullscreen',
      //swipeToClose: true,
      componentProps: {
        'page': "human_history",
        'pagename': name + 'の業務経歴',
        'human_history': str.human_history ,
      }
    });
    
    modal.onWillDismiss().then((getdata) => {
      //console.log("getdata " + JSON.stringify(getdata));
      /*
      let data = JSON.parse(JSON.stringify(getdata)).data
      let page = data.page
      let item = data.item
      //console.log("################ page " + JSON.stringify(page))   
      console.log("################ data " + JSON.stringify(data))      
      console.log("################ item " + JSON.stringify(item))
      */
    });
    return await modal.present();
  }



}
