import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import {LoadingService} from './service/loading.service'
import {EventsService} from './service/events.service';
import {ApiService} from './service/api.service';

import firebase from 'firebase/app';    //8
import "firebase/database";
import "firebase/auth";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: '案件情報 一覧 ', url: '/job', icon: 'terminal' },
    { title: '人材情報 一覧', url: '/human', icon: 'reader' },
    { title: '案件情報 追加 ', url: '/job-add', icon: 'shapes' },
    { title: '人材情報 追加', url: '/human-add', icon: 'person-add' },
    { title: '案件情報 一覧 ', url: '/job/release', icon: 'rocket' },
    { title: '人材情報 一覧', url: '/human/release', icon: 'accessibility' },
  ];
  //public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public appOpenPages = [
  ]
  

  isMenuSizeWidth
  isMenuWide

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public events: EventsService,
    public loadingService: LoadingService,
    public api: ApiService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {

    this.isMenuSizeWidth = "{--side-width: 220px!important}"
  }


  ngOnInit() {

  }

  changeMenuSize(str){
    if(str == "close"){
      this.isMenuSizeWidth = "{--side-width: 40px!important}"
      this.isMenuWide = false
      // console.log("isMenuSizeWidth " + this.isMenuSizeWidth)
      // 表示されているデータの再描画
      this.changeDetectorRef.detectChanges(); //変更された this　の要素を強制的に変更
    }else{
      this.isMenuSizeWidth = "{--side-width: 220px!important}"
      this.isMenuWide = true
      // console.log("isMenuSizeWidth " + this.isMenuSizeWidth)
      // 表示されているデータの再描画
      this.changeDetectorRef.detectChanges(); //変更された this　の要素を強制的に変更
    }

  }

}
